module.exports.getPostLinkDest = post => {
  let isExternal, link
  const tags = post.tags.map(x => x.name)

  isExternal = tags.includes('external-link')
  
  if (isExternal) {
    link = post.custom_excerpt
  } else {
    link = `/blog/${post.slug}`
  }

  return {
      link,
      isExternal,
  }
}

module.exports.getPostType = post => {
  if (!post.tags) return null;
  const tags = post.tags.map(x => x.name)

  if (tags.includes('talk')) return 'talk';
  if (tags.includes('video')) return 'video';

  return 'post';
}

module.exports.postTypeClasses = {
  'post': 'text-blue-600',
  'talk': 'text-red-600',
  'video': 'text-purple-600',
}